// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { Link as RouterLink } from 'gatsby';
import { ifProp, prop, theme } from 'styled-tools';
import { states, units } from 'react-components/styles';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  'aria-label'?: string,
  as?: Node,
  children?: Node,
  className?: string,
  disabled?: boolean,
  href?: string,
  icon: string,
  iconSize?: string,
  rel?: string,
  size: string,
  target?: '_blank' | '_self' | '_parent' | '_top',
  to?: string,
  type?: string,
  variant?: 'fill' | 'outline'
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span`
  align-items: center;
  display: grid;
  height: ${prop('size')};
  justify-items: center;
  transition: color ${theme('animations.fastTransition')};
  width: ${prop('size')};

  ${ifProp('hasChildren', css`
    grid-template-columns: max-content 1fr;
    margin-right: ${units(1)};
  `)}
`;

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs(({
  as,
  href,
  to,
  type
}: Props) => {
  const element = as ?? (to && RouterLink) ?? (href && 'a') ?? 'button';

  return {
    as: element,
    type: type ?? (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  line-height: 15px;
  padding: 0;
  text-align: center;
  transition: background-color ${theme('transitions.default')};

  ${ifProp('hasChildren', css`
    align-items: center;
    display: flex;
    flex-direction: row;
  `)}

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${states.action`
    outline: none;
    text-decoration: none;
  `}
`;

/**
 * `IconButton` component.
 */

const IconButton = (props: Props): Node => {
  const {
    children,
    icon,
    iconSize,
    size,
    ...rest
  } = props;

  return (
    <Button
      {...rest}
      hasChildren={!!children}
    >
      <Wrapper
        hasChildren={!!children}
        size={size}
      >
        <Icon
          icon={icon}
          size={iconSize}
        />
      </Wrapper>

      {children}
    </Button>
  );
};

/**
 * Export `IconButton` component.
 */

export default IconButton;
