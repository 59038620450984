// @flow

/**
 * Module dependencies.
 */

import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import React, { type Node } from 'react';

/**
 * Export `MetatagsProps` type.
 */

export type MetatagsProps = {|
  description?: ?string,
  keywords?: ?string,
  title?: ?string
|};

/**
 * Metadata query.
 */

const metadataQuery = graphql`
  query {
    seegnoCmsSetting(
      code: {
        eq: "socialPic"
      }
    ) {
      file {
        value {
          url
        }
      }
    }
    site {
      siteMetadata {
        author
        defaultLang
        description
        title
        titleTemplate
        version
      }
    }
  }
`;

/**
 * `Metatags` component.
 */

const Metatags = (props: MetatagsProps): Node => {
  const { description, keywords, title } = props;
  const data = useStaticQuery(metadataQuery);
  const defaultMetatags = data.site.siteMetadata;
  const htmlAttributes = { lang: defaultMetatags.defaultLang };
  const image = data?.seegnoCmsSetting?.file?.value?.url;

  return (
    <Helmet
      defaultTitle={defaultMetatags.title}
      htmlAttributes={htmlAttributes}
      titleTemplate={defaultMetatags.titleTemplate}
    >
      {title && (
        <title>
          {title}
        </title>
      )}

      <meta
        content={description || defaultMetatags.description}
        name={'description'}
      />

      {keywords && (
        <meta
          content={keywords}
          name={'keywords'}
        />
      )}

      <meta
        content={defaultMetatags.author}
        name={'author'}
      />

      {image && (
        <meta
          content={image}
          name={'image'}
        />
      )}

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={title || defaultMetatags.title}
        property={'og:title'}
      />

      <meta
        content={description || defaultMetatags.description}
        property={'og:description'}
      />

      {image && (
        <meta
          content={image}
          property={'og:image'}
        />
      )}

      <meta
        content={defaultMetatags.title}
        property={'og:site_name'}
      />

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={title || defaultMetatags.title}
        property={'twitter:title'}
      />

      <meta
        content={description || defaultMetatags.description}
        property={'twitter:description'}
      />

      <meta
        content={defaultMetatags.author}
        property={'twitter:site'}
      />

      <meta
        content={defaultMetatags.author}
        property={'twitter:creator'}
      />

      <meta
        content={defaultMetatags.version}
        name={'version'}
      />
    </Helmet>
  );
};

/**
 * Export `Metatags` component.
 */

export default Metatags;
