// @flow

/**
 * Module dependencies.
 */

import type { Node } from 'react';
import { Link as RouterLink } from 'gatsby';
import { omit } from 'lodash';
import { regexes } from './regexes';

/**
 * `DisabledProps` type.
 */

type DisabledProps = {
  disabled?: boolean,
  tabIndex?: number
};

/**
 * `LinkProps` type.
 */

type LinkProps = {
  ...DisabledProps,
  as?: Node,
  href?: string
};

/**
  * Export `isExternalRoute` util.
  */

export function isExternalRoute(route: string): boolean {
  return regexes.url.test(route) || regexes.telLink.test(route);
}

/**
  * Export `getDisabledProps` util.
  */

export function getDisabledProps(props: DisabledProps): Object {
  const { disabled, tabIndex, ...rest } = props;

  return {
    ...omit(rest, 'theme'),
    'aria-disabled': disabled,
    disabled,
    tabIndex: tabIndex || disabled ? -1 : null
  };
}

/**
  * Export `getLinkProps` util.
  */

export function getLinkProps({ href, ...rest }: LinkProps): Object {
  if (!href) {
    return {
      ...rest,
      ...getDisabledProps(rest)
    };
  }

  return isExternalRoute(href) ? {
    ...getDisabledProps(rest),
    as: rest?.as ?? 'a',
    href,
    rel: 'noopener',
    target: '_blank'
  } : {
    ...getDisabledProps(rest),
    as: rest?.as ?? RouterLink,
    href: null,
    to: href
  };
}
