// @flow

/**
 * Module dependencies.
 */

import type { ComponentType, Node } from 'react';
import { color, states, units } from 'react-components/styles';
import { getLinkProps } from 'utils/get-link-props';
import { ifProp, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {|
  as?: Node,
  children: Node,
  className?: string,
  colorTheme?: 'primary' | 'white',
  disabled?: boolean,
  fullWidth?: boolean,
  href?: string,
  onClick?: () => void,
  rel?: string,
  size?: 'medium' | 'small',
  target?: string,
  to?: string,
  type?: string,
  variant?: 'fill' | 'outline'
|};

/**
 * Create button theme.
 */

const createButtonTheme = (
  main: string,
  hover: string,
  textColor: string
) => css`
  border-color: ${color(main)};

  ${ifProp({ variant: 'outline' }, css`
    background-color: transparent;
    color: ${color(main)};

    ${states.interaction`
      background-color: ${color(main)};
      border-color: ${color(main)};
      color: ${color(textColor)};
    `}
  `, css`
    background-color: ${color(main)};
    color: ${color(textColor)};

    ${states.interaction`
      background-color: ${color(hover)};
      border-color: ${color(hover)};
    `}
  `)}
`;

/**
 * `Button` styled component.
 */

const Button: ComponentType<ButtonProps> = styled.button.attrs(({
  colorTheme,
  size,
  ...rest
}: ButtonProps) => ({
  ...getLinkProps(rest),
  colorTheme: colorTheme || 'primary',
  size: size || 'medium'
}))`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: ${theme('transitions.default')};
  transition-property: background-color, border-color, color;
  white-space: nowrap;

  ${ifProp('fullWidth', 'width: 100%;')}
  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${states.action`
    outline: none;
    text-decoration: none;
  `}

  ${switchProp('size', {
    medium: css`
      ${theme('typography.styles.h5')}

      font-weight: 500;
      min-height: 3.5rem;
      padding: 0.25rem 2rem;
    `,
    small: css`
      ${theme('typography.styles.paragraph')}

      min-height: ${units(5)};
      padding: 0.25rem 1.75rem;
    `
  })}

  ${switchProp('colorTheme', {
    primary: createButtonTheme('green500', 'green300', 'grey800'),
    secondary: createButtonTheme('blue800', 'blue800', 'white'),
    white: createButtonTheme('white', 'grey100', 'blue800')
  })}
`;

/**
 * Export `Button` component.
 */

export default Button;
