// @flow

/**
 * Module dependencies.
 */

import { columnWidth, media, setStyledProps } from 'react-components/styles';
import { theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * Export `Box` component.
 */

export const Box = styled.div`
  ${props => setStyledProps(props, ['as', 'forwardedComponent', 'style'])}
`;

/**
 * Export `Container` component.
 */

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme('containerMaxWidth')};
  padding-left: ${columnWidth(0.75)}%;
  padding-right: ${columnWidth(0.75)}%;
  position: relative;
  width: 100%;

  ${media.min('md')`
    padding-left: ${columnWidth(1)}%;
    padding-right: ${columnWidth(1)}%;
  `}
`;

/**
 * Export layout components.
 */

export { Fill } from 'react-components/layout';
